import React, { Component } from "react";
import { Col, Navbar, Nav, Form, Row, Button } from "react-bootstrap";
import { BeatLoader } from "react-spinners";

import Keycloak from "keycloak-js";

import Utils from "../../utils/Utils";
import Backend from "../../services/Backend";
import DateUtils from '../../utils/DateUtils';

import "./App.css";
import CategoryResultTable from "../category/CategoryResultTable";
import CategorySearch from "../category/CategorySearch";
import Logout from "../logout/Logout";

export default class App extends Component {

    constructor(props) {
        super(props);

        this.REST_API = process.env.REACT_APP_REST_SERVER_URL;

        this.utils = new Utils();

        this.backend = new Backend();

        this.dateUtils = new DateUtils();

        this.state = {
            categories: [],
            organisations: undefined,
            backend: this.backend, // module to talk to back-end services
            utils: this.utils, // various generic helper methods
            keycloak: null,
            authenticated: false,
            monthCount: 4,
            ametiqContractNr: undefined,
            dateUtils: this.dateUtils, // date-related helper functions
            selectedView: 0, // 0 -> Daily View , 1 -> Monthly View
            isLoadingCategories: false
        }
    }

    componentDidMount() {

        const KEY_CLOACK = process.env.REACT_APP_KEY_CLOACK_FILE
        console.log("FILE PATH", KEY_CLOACK);
        let currentComponent = this;

        const keycloak = Keycloak(KEY_CLOACK);

        keycloak.init({ onLoad: "login-required" }).success(function (authenticated) {
            currentComponent.setState({ keycloak: keycloak, authenticated: authenticated });

            console.log(keycloak);
            console.log(authenticated);
            // console.log(getState().keycloakLogin);
            if (!authenticated) {
                window.location.reload();
            } else {
                console.info("Authenticated");
            }
/* 
            //store authentication tokens in sessionStorage
            sessionStorage.setItem('authentication', keycloak.token);
            sessionStorage.setItem('refreshToken', keycloak.refreshToken);
 */

            setInterval(() => {
                keycloak.updateToken(40).error(function () {
                    console.log("Token could not be refreshed");
                    keycloak.logout();
                });
                }, 300000); //refresh token after 5min

            if (keycloak.realmAccess.roles.filter(role => role === "readonly").length > 0) {
                currentComponent.getOrganisations(keycloak);
                currentComponent.searchCategories(null, keycloak);
            }

        }).error(function (error) {
            alert("failed to initialize");
            console.log(error);
        });

    }

    changeView(view) {

        this.setState({
            selectedView: view
        },
            function () {
                this.searchCategories(null, this.state.keycloak);
            }
        );

    }

    selectedMonthCountChanged(event) {

        this.setState({
            monthCount: event.target.value - 1
        },
            function () {
                this.searchCategories(null, this.state.keycloak);
            }
        );

    }


    render() {

        let toDisplay;

        if (this.state.categories) {
            toDisplay = (
                <div>
                    <div className="col">
                        <div className="card mb-3">
                            <div className="card-body">
                                <Form>
                                    <Form.Group controlId="selectMonthCount" as={Row}  >
                                        {this.state.selectedView === 0 ?
                                            <Form.Label column sm={{ span: 1, offset: 10 }} className="month-count-title">Days: </Form.Label>
                                            :
                                            <Form.Label column sm={{ span: 1, offset: 10 }} className="month-count-title">Months: </Form.Label>

                                        }

                                        <Col sm={1}>
                                            <Form.Control as="select" defaultValue={this.state.monthCount + 1} onChange={this.selectedMonthCountChanged.bind(this)}>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                                <option>6</option>
                                                <option>7</option>
                                                <option>8</option>
                                                <option>9</option>
                                                <option>10</option>
                                                <option>11</option>
                                                <option>12</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>
                                </Form>
                                <CategoryResultTable categories={this.state.categories} keycloak={this.state.keycloak} months={this.state.monthCount} view={this.state.selectedView} 
                                                    ametiqContractNr={this.state.ametiqContractNr}/>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (this.props.isLoadingBusinessBookings) {
            toDisplay =
                <BeatLoader
                    css={"display: block; margin: 0 auto; width: fit-content;"}
                    sizeUnit={"px"}
                    size={15}
                    color={"#A9DB7D"}
                    loading={true}
                />
        } else {
            toDisplay = (
                <div>
                    <div className="col">
                        <div className="card mb-3">
                            <div className="card-body">
                                No entries where found.
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        const organisations = this.state.organisations;

        let search;

        search = <CategorySearch searchCategories={this.searchCategories.bind(this)} organisations={organisations} keycloak={this.state.keycloak} />;

        if (this.state.keycloak) {
            if (this.state.authenticated) return (

                <React.Fragment>
                    <Navbar bg="light" expand="lg">
                        <Navbar.Brand ><img className="my-auto" src={require("../../images/logo.png")} height="30px" alt="Logo" /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                <Nav>Betriebs Übersicht</Nav>
                            </Nav>

                            <Button className="view-change" onClick={this.changeView.bind(this, 0)}>Daily</Button>
                            <Button className="view-change" onClick={this.changeView.bind(this, 1)}>Monthly</Button>

                            <Logout keycloak={this.state.keycloak} />
                        </Navbar.Collapse>
                    </Navbar>

                    <Col className="body-col">
                        {search}
                        {toDisplay}
                    </Col>
                </React.Fragment>
            ); else return (<div>Unable to authenticate!</div>)
        }
        return (
            <div>Initializing Keycloak...</div>
        );

    }

    async searchCategories(value, keycloak) {

        this.setState({
            categories: [],
            isLoadingCategories: true,
        });


        try {
            let queryParams = this.getQueryParamsCategories(value);
            const response = await this.backend.get(this.REST_API + "/api/booking/v1/bookings", queryParams, keycloak);

            console.log('response booking service:', response);

            //this.verifyResponse(response);
            this.setState({
                categories: response.categories,
                isLoadingCategories: false
            });

        } catch (error) {
            this.setState({
                isLoadingCategories: false
            });
            console.error(error);
            //const errorMessage = "FEHLER" + error;
            //this.setState({error: true, errorMessage: errorMessage});
        }
    }

    getQueryParamsCategories(value) {
        let queryParams = "/?";
        if (value) {
            queryParams = queryParams.concat("contractNr=" + value + "&");
            this.setState({ametiqContractNr: value});
        }
        else if(value === null && this.state.ametiqContractNr){
            queryParams = queryParams.concat("contractNr=" + this.state.ametiqContractNr + "&");
        }else{
            this.setState({ametiqContractNr: undefined});
        }

        var now = new Date();
        var fromDate = new Date();

        var toDateString;
        var fromDateString;

        toDateString = new Date(now.getTime() - (now.getTimezoneOffset() * 60000))
            .toISOString()
            .split("T")[0];

        switch (this.state.selectedView) {
            case 0:

                fromDate.setDate(fromDate.getDate() - this.state.monthCount);

                fromDateString = new Date(fromDate.getTime() - (fromDate.getTimezoneOffset() * 60000))
                    .toISOString()
                    .split("T")[0];

                queryParams += "format=DAILY_AGGREGATION";


                break;
            case 1:

                fromDate.setMonth(fromDate.getMonth() - this.state.monthCount);

                fromDateString = this.state.dateUtils.formatePeriodeToFirstDayOfMonth(fromDate);

                queryParams += "format=MONTHLY_AGGREGATION";


                break;
            default:
                console.log("View could not be found.");
        }

        queryParams += "&toDate=" + toDateString;
        queryParams += "&fromDate=" + fromDateString;

        return queryParams;
    }

    async getOrganisations(keycloak) {

        try {
            const response = await this.backend.get(this.REST_API + "/api/bookings/organisations/", null, keycloak);
            //this.verifyResponse(response);

            this.setState({
                organisations: response.data.organisation
            });
        } catch (error) {
            console.error(error);
            /* const errorMessage = <Translate text="error.default-text"/>;
            this.setState({error: true, errorMessage: errorMessage}); */
        }
    }

}